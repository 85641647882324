/**
 * Reads contents of a file selected by user, written for use with async/await
 *
 * @param file a File from an input element
 * @returns typically, an ArrayBuffer, but typescript shows that it can be string or null as well
 */
export const readFileInput = async (
  file: File,
): Promise<string | ArrayBuffer | null | undefined> => {
  return new Promise((res, rej) => {
    if (!file) {
      return rej('no file');
    }
    var reader = new FileReader();
    reader.onload = function (loadEvent) {
      var result = loadEvent.target?.result;
      return res(result);
    };

    reader.readAsArrayBuffer(file);
  });
};
