/**
 * Pathname without trailing slash, query or hash.
 *
 * @param pathname a location.pathname
 */
export const getShortPath = (pathname: string) => {
  const path = pathname?.match(/^\/?[^?#]*/)?.[0];
  return path && path.length > 1 ? path.replace(/\/$/, '') : path;
};

/**
 * Matches page's pathname to a link path, ignoring querystring/hash.
 *
 * @param pathname Page's pathname to match
 * @param to Either a slug or a path string to compare
 */
export const isOnPage = (pathname: string, to: string) => {
  const shortPath = getShortPath(pathname);
  const shortTo = getShortPath(to);
  return (
    !!shortPath &&
    !!shortTo &&
    new RegExp(`^/?${shortPath}$`, 'i').test(shortTo)
  );
};
