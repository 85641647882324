export const extractSlug = (path: string) =>
  path.split('/').pop()?.split('.').shift() || path;

export const mapFrontmatter = <F = unknown>(data: {
  edges: { node: { frontmatter: F; fileAbsolutePath: string } }[];
}) => data.edges.map((edge) => edge.node.frontmatter);

export const frontmatterWithSlug = <F = unknown>(data: {
  edges: { node: { frontmatter: F; fileAbsolutePath: string } }[];
}) =>
  data.edges.map((edge) => ({
    ...edge.node.frontmatter,
    slug: edge.node.fileAbsolutePath
      ? extractSlug(edge.node.fileAbsolutePath)
      : undefined,
  }));
