import { isSSG, LocalStorageKey } from '~/config';

export const getStoredItem = (key: LocalStorageKey) => {
  if (!isSSG && key) {
    const item = localStorage.getItem(key.toString());
    if (item) {
      return JSON.parse(item);
    }
  }
};
export const storeItem = (key: LocalStorageKey, value) => {
  if (!isSSG && key) {
    if (value) {
      localStorage.setItem(key.toString(), JSON.stringify(value));
    } else {
      removeItem(key);
    }
  }
};

export const getStoredSessionItem = (key: LocalStorageKey) => {
  if (!isSSG && key) {
    const item = sessionStorage.getItem(key.toString());
    if (item) {
      return JSON.parse(item);
    }
  }
};
export const storeSessionItem = (key: LocalStorageKey, value) => {
  if (!isSSG && key) {
    if (value) {
      sessionStorage.setItem(key.toString(), JSON.stringify(value));
    } else {
      removeItem(key);
    }
  }
};

export const removeItem = (key: LocalStorageKey) =>
  !isSSG && key && localStorage.removeItem(key.toString());

export const getStoredBoolean = (key: LocalStorageKey): boolean =>
  !isSSG && key && localStorage.getItem(key.toString()) === 'true';

export const storeBoolean = (key: LocalStorageKey, value = true) =>
  !isSSG && key && localStorage.setItem(key.toString(), JSON.stringify(value));

export const getStoredSessionBoolean = (key: LocalStorageKey): boolean =>
  !isSSG && key && sessionStorage.getItem(key.toString()) === 'true';

export const storeSessionBoolean = (key: LocalStorageKey, value = true) =>
  !isSSG &&
  key &&
  sessionStorage.setItem(key.toString(), JSON.stringify(value));
