export const randomHex = () => {
  const hex = ((Math.random() * 0xffffff) << 0).toString(16);
  return hex.padEnd(6, hex.slice(-1));
};

export const isExternalUrl = (to: string) => /^https?:\/\//.test(to);

export const prependSlash = (to: string) =>
  !to || to.charAt(0) === '/' ? to : `/${to}`;

/** converts an object into a querystring */
export const encodeQueryString = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};
